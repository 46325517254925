<template>
    <v-main class="container-prosker">
        <v-icon class="mt-5 mb-0" @click="goBack">mdi-arrow-left</v-icon>
        <v-row>
            <v-col cols="12">
                <div class="d-flex flex-column align-center">
                    <h2 class="font-weight-bold black--text mt-0 same-line">
                        {{ couponData.code }}
                    </h2>
                </div>
            </v-col>
            <template>
                <v-expansion-panels v-model="panel" multiple class="mb-10">
                    <CouponInfoPanel :couponData="couponData" :fetchCoupon="fetchCoupon" :isLoading="isLoadingCouponInformation" />
                </v-expansion-panels>
            </template>
        </v-row>
    </v-main>
</template>
<script>
    import axios from 'axios';
    import errorDialog from '@/components/socialvue/mixins/errorDialog';
    import CouponInfoPanel from '@/views/Pages/Admin/EditCoupons/CouponInfoPanel';

    export default {
        components: {
            CouponInfoPanel
        },
        data () {
            return {
                isLoadingCouponInformation: false,
                couponData: {},
                panel: [0]
            };
        },
        created () {
            this.fetchCoupon();
            this.setImportedConstants();
        },
        computed: {
        },
        mixins: [errorDialog],
        methods: {
            fetchCoupon () {
                this.isLoadingCouponInformation = true;
                const code = this.$route.params.id;
                const url = `${process.env.VUE_APP_BASE_URL}/api/promotional-codes?filters=code=${code}`;
                axios
                    .get(url)
                    .then((response) => {
                        const coupons = response.data.data; // Array de cupones
                        if (coupons.length > 0) {
                            this.couponData = coupons[0]; // Accede al primer cupón
                        } else {
                            this.couponData = {}; // Si no hay resultados, define un objeto vacío
                        }
                        this.isLoadingCouponInformation = false;
                    })
                    .catch((error) => {
                        this.showError(error);
                        this.isLoadingCouponInformation = false;
                    });
            },
            goBack () {
                this.$router.go(-1)
            }
        }
    };
</script>
<style scoped>
    .userPicture {
        height: 150px;
        width: 150px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
</style>

<template>
<v-expansion-panel>
    <v-expansion-panel-header>
        {{ $t('couponCode') }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
        <AddEditCouponForm :coupon="couponData" :on-submit="submitCouponInfos" :isLoading="couponFormIsSubmitting" />
    </v-expansion-panel-content>
</v-expansion-panel>
</template>

<script>
import axios from 'axios';
import AddEditCouponForm from '@/components/socialvue/forms/AddEditCouponForm';

export default {
  name: 'CouponInfoPanel',
  components: {
    AddEditCouponForm
  },
  props: {
    couponData: {
      type: Object,
      required: true
    },
    fetchCoupon: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      couponFormIsSubmitting: false,
      couponId: this.$route.params.id
    };
  },
  created () {
    this.fetchCouponData();
  },
  methods: {
    fetchCouponData () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/promotional-codes/${this.couponId}`;
      axios.get(url)
        .then(response => {
          this.couponData = response.data;
        })
        .catch(error => {
          console.error('Error al obtener el cupón:', error);
        });
    },
    submitCouponInfos (data) {
      console.log(data);
      const url = `${process.env.VUE_APP_BASE_URL}/api/promotional-codes/${this.couponData.id}`;
      this.couponFormIsSubmitting = true;
      axios.put(url, data)
        .then(() => {
          this.couponFormIsSubmitting = false;
        })
        .then(() => {
          this.couponFormIsSubmitting = false;
          this.fetchCoupon();
        })
        .catch((error) => {
          this.couponFormIsSubmitting = false;
          this.showError(error);
        });
    }
  }
};
</script>

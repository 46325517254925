<template>
  <v-form ref="form" lazy-validation @submit.prevent="submitForm">
    <v-text-field
      v-model="coupon.code"
      :label="$t('couponCode')"
      :disabled="isLoading"
      required
      :rules="couponRules"
    />
    <v-autocomplete :label="$t('couponDiscountType')" :items="discountType" :search-input.sync="searchInput" @change="searchInput = ''" v-model="coupon.discount_type" item-text="name" item-value="discount_type" color="#59D79D" item-color="#59D79D"></v-autocomplete>
    <v-text-field
      v-model="coupon.amount"
      :label="$t('couponAmount')"
      type="number"
      :disabled="isLoading"
      required
      :rules="couponAmountRules"
    />
    <v-text-field
      v-model="coupon.minimum_order_value"
      :label="$t('couponMinOrderValue')"
      type="number"
      :disabled="isLoading"
      required
      :rules="couponMinValueRules"
    />
    <v-row>
      <v-col cols="6">
        <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDateFormatted"
              :label="$t('startDate')"
              readonly
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="coupon.start_date"
            no-title
            @input="menuStartDate = false"
            :locale="$i18n.locale"
            show-adjacent-months
          />
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDateFormatted"
              :label="$t('endDate')"
              readonly
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="coupon.end_date"
            no-title
            @input="menuEndDate = false"
            :locale="$i18n.locale"
            show-adjacent-months
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-text-field
      v-model="coupon.usage_limit"
      :label="$t('couponUsageLimit')"
      type="number"
      :disabled="isLoading"
      :rules="couponLimitRules"
    />
    <v-switch
      v-model="coupon.enabled"
      :label="$t('couponEnabled')"
      :disabled="isLoading"
    />
    <v-spacer></v-spacer>
    <slot name="actionArea" :submitForm="submitForm" :resetForm="resetForm"></slot>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <save-button :save="submitForm" :loading="isLoading" :message="$t('save')" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';

export default {
  name: 'AddEditCouponForm',
  props: {
    coupon: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    onSubmitSuccess: {
      type: Function,
      required: true
    }
  },
  components: {
    SaveButton
  },
  data () {
    return {
      discountType: [
        { id: 0, discount_type: 'percentage', name: this.$t('couponPercentage') },
        { id: 1, discount_type: 'fixed_amount', name: this.$t('couponFixMount') }
      ],
      couponRules: [v => !!v || this.$t('fieldCouponRequired')],
      couponAmountRules: [v => !!v || this.$t('fieldCouponAmountRequired')],
      couponMinValueRules: [v => !!v || this.$t('fieldCouponMinValueRequired')],
      couponLimitRules: [v => !!v || this.$t('fieldCouponLimitRequired')],
      menuStartDate: false,
      menuEndDate: false,
      startDateFormatted: null,
      endDateFormatted: null
    };
  },
  watch: {
    'coupon.start_date': function (val) {
      this.startDateFormatted = this.formatDate(val);
    },
    'coupon.end_date': function (val) {
      this.endDateFormatted = this.formatDate(val);
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return this.$i18n.locale.toLowerCase().includes('en') ? `${month}/${day}/${year}` : `${day}/${month}/${year}`;
    },
    async submitForm () {
        if (this.isLoading) {
          return;
        }

        if (this.$refs.form.validate()) {
            const couponData = {
              ...this.coupon
            };

            try {
                await this.onSubmit(couponData);
                if (this.onSubmitSuccess) {
                    await this.onSubmitSuccess(couponData);
                }
            } catch (error) {
                console.error('Error submitting the form:', error);
            }
        }
    },
    resetForm () {
      this.$refs.form.reset();
    },
    hasActionSlot () {
      return !!this.$scopedSlots.actionArea;
    }
  }
};
</script>
